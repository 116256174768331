<template>
	<div class="main-container">
		<div class="flex flex-center flex-wrap">
			<statistics-time :unShowType='unShowType' @getRangeTime='getRangeTime'></statistics-time>
			<div class="flex flex-center" style="margin-left:20px">
				<div class="con_item">浏览访问：</div>
				<el-select placeholder="请选择" v-model="Referer" style="width:200px" @change="chooseselect">
					<el-option v-for="(v,i) in Refererlist" :key="i" :value="v.value" :label="v.label"></el-option>
				</el-select>
			</div>
		</div>
		<div class="contents" style="margin-top:10px">
			<div class="con_item" style="display: flex;">
				<span>流量来源：</span>
				<div class="con_items" style="display: flex;flex-wrap: wrap;">
					<div class="item_detail">
						<el-checkbox :label="1" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(1)">浏览量</el-checkbox>
						<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.PV" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
					</div>
					<div class="item_detail">
						<el-checkbox  :label="2" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(2)">访客数</el-checkbox>
						<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.UV" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
					</div>
					<div class="item_detail">
						<el-checkbox :label="3" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(3)">点击次数</el-checkbox>
						<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.ClickCount" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
					</div>
					<div class="item_detail">
						<el-checkbox :label="4" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(4)">点击人数</el-checkbox>
						<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.ClickMemberCount" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
					</div>
					<div class="item_detail">
						<el-checkbox :label="5" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(5)">点击率</el-checkbox>
						<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.ClickRate" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
					</div>
					<div class="item_detail">
						<el-checkbox :label="6" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(6)">跳失率</el-checkbox>
						<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.BounceRate" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
					</div>
					<div class="item_detail">
						<el-checkbox :label="7" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(7)">平均停留时长</el-checkbox>
						<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.StayTimeAvg" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
					</div>
					<div class="item_detail" v-if="Referer!=100008">
						<el-checkbox :label="8" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(8)">分享访问次数</el-checkbox>
						<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.ShareVisitCount" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
					</div>
					<div class="item_detail" v-if="Referer!=100008">
						<el-checkbox :label="9" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(9)">分享访问人数</el-checkbox>
						<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.ShareVisitMemberCount" placement="top-start">
							<i class="el-icon-warning icon"></i>
						</el-tooltip>
					</div>
				</div>
			</div>
			<div class="con_item" style="display: flex;" v-if="timeType!=0">
				<span>流量转化：</span>
				<div style="flex:1">
					<div class="con_items" style="display: flex;flex-wrap: wrap;">
						<div class="item_detail">
							<el-checkbox :label="10" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(10)">引导下单金额</el-checkbox>
							<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.GuideSubmitOrderMoney" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item_detail">
							<el-checkbox :label="11" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(11)">引导下单人数</el-checkbox>
							<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.GuideSubmitOrderMemberCount" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item_detail">
							<el-checkbox :label="12" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(12)">引导访问-下单转化率</el-checkbox>
							<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.GuideSubmitRate" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item_detail">
							<el-checkbox :label="13" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(13)">引导支付金额</el-checkbox>
							<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.GuidePayOrderMoney" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item_detail">
							<el-checkbox :label="14" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(14)">引导支付人数</el-checkbox>
							<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.GuidePayOrderMemberCount" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item_detail">
							<el-checkbox :label="15" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(15)">引导访问-支付转化率</el-checkbox>
							<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.GuidePayRate" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
					</div>
					<div class="con_items" style="display: flex;flex-wrap: wrap;">
						<div class="item_detail">
							<el-checkbox :label="16" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(16)">启动下单金额</el-checkbox>
							<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.StartSubmitOrderMoney" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item_detail">
							<el-checkbox :label="17" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(17)">启动下单人数</el-checkbox>
							<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.StartSubmitOrderMemberCount" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item_detail">
							<el-checkbox :label="18" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(18)">启动访问-下单转化率</el-checkbox>
							<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.StartSubmitRate" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item_detail">
							<el-checkbox :label="19" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(19)">启动支付金额</el-checkbox>
							<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.StartPayOrderMoney" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item_detail">
							<el-checkbox :label="20" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(20)">启动支付人数</el-checkbox>
							<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.StartPayOrderMemberCount" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
						<div class="item_detail">
							<el-checkbox :label="21" v-model="Checks" @change='handleStructureCheck' :disabled="Checks.length>4&&!Checks.includes(21)">启动访问-支付转化率</el-checkbox>
							<el-tooltip class="item-tooltip" style="margin-left:8px;" popper-class="data-analysis-item-tooltip" effect="light" :content="tooltip.StartPayRate" placement="top-start">
								<i class="el-icon-warning icon"></i>
							</el-tooltip>
						</div>
					</div>
				</div>
			</div>
			<div style="color: #666666;font-size: 14px;padding-left:67px;">最多只能同时选择5个指标</div>
		</div>
		<div class="filter-container" style="margin-top: 20px;">
			<div class="filter-item">
				<el-input v-model="keywords" placeholder="请输入关键字"></el-input>
			</div>
			<div class="filter-item">
				<el-button type="primary" @click="handleSearch">搜索</el-button>
			</div>
		</div>
		<div class="table">
			<el-table :data="tableData" style="width: 100%"  @sort-change="sortChange" >
				<el-table-column  label="访问页面" width="">
					<template  slot-scope="scope">
						<span>{{scope.row.PageName}}</span>
						<span v-if="scope.row.IsHomePage" style="margin-left:5px;font-size:12px;line-height:20px;border-radius: 2px;backgroundColor:green;color:#fff;padding:3px;">
							商城首页
						</span>
					</template>
				</el-table-column>
				<el-table-column v-if="Checks.includes(1)" prop="PV" sortable label="浏览量"></el-table-column>
				<el-table-column v-if="Checks.includes(2)" prop="UV" sortable label="访客数"></el-table-column>
				<el-table-column v-if="Checks.includes(3)" prop="ClickCount" sortable label="点击次数"></el-table-column>
				<el-table-column v-if="Checks.includes(4)" prop="ClickMemberCount" sortable label="点击人数"></el-table-column>
				<el-table-column v-if="Checks.includes(5)" prop="ClickRate" sortable label="点击率">
					<template slot-scope="scope">
						{{scope.row.ClickRate}}%
					</template>
				</el-table-column>
				<el-table-column v-if="Checks.includes(6)" prop="BounceRate" sortable label="跳失率">
					<template slot-scope="scope">
						{{scope.row.BounceRate}}%
					</template>
				</el-table-column>
				<el-table-column v-if="Checks.includes(7)" prop="StayTimeAvg" sortable label="平均停留时长（秒）"></el-table-column>
				<el-table-column v-if="Checks.includes(8)" prop="ShareVisitCount" sortable label="分享访问次数"></el-table-column>
				<el-table-column v-if="Checks.includes(9)" prop="ShareVisitMemberCount" sortable label="分享访问人数"></el-table-column>
				<el-table-column v-if="Checks.includes(10)" prop="GuideSubmitOrderMoney" sortable label="引导下单金额"></el-table-column>
				<el-table-column v-if="Checks.includes(11)" prop="GuideSubmitOrderMemberCount" sortable label="引导下单人数"></el-table-column>
				<el-table-column v-if="Checks.includes(12)" prop="GuideSubmitRate" sortable label="引导-访问下单转化率">
					<template slot-scope="scope">
						{{scope.row.GuideSubmitRate}}%
					</template>
				</el-table-column>
				<el-table-column v-if="Checks.includes(13)" prop="GuidePayOrderMoney" sortable label="引导支付金额"></el-table-column>
				<el-table-column v-if="Checks.includes(14)" prop="GuidePayOrderMemberCount" sortable label="引导支付人数"></el-table-column>
				<el-table-column v-if="Checks.includes(15)" prop="GuidePayRate" sortable label="引导访问-支付转化率">
					<template slot-scope="scope">
						{{scope.row.GuidePayRate}}%
					</template>
				</el-table-column>

				<el-table-column v-if="Checks.includes(16)" prop="StartSubmitOrderMoney" sortable label="启动下单金额"></el-table-column>
				<el-table-column v-if="Checks.includes(17)" prop="StartSubmitOrderMemberCount" sortable label="启动下单人数"></el-table-column>
				<el-table-column v-if="Checks.includes(18)" prop="StartSubmitRate" sortable label="启动-访问下单转化率">
					<template slot-scope="scope">
						{{scope.row.StartSubmitRate}}%
					</template>
				</el-table-column>
				<el-table-column v-if="Checks.includes(19)" prop="StartPayOrderMoney" sortable label="启动支付金额"></el-table-column>
				<el-table-column v-if="Checks.includes(20)" prop="StartPayOrderMemberCount" sortable label="启动支付人数"></el-table-column>
				<el-table-column v-if="Checks.includes(21)" prop="StartPayRate" sortable label="启动访问-支付转化率">
					<template slot-scope="scope">
						{{scope.row.StartPayRate}}%
					</template>
				</el-table-column>
			</el-table>
			
			<el-pagination style="margin-top:20px;float:right;" v-if="page.total" @size-change="handleSizeChange"
			 @current-change="handleCurrentChange" :current-page="page.current" :page-sizes="[10, 20, 30, 40]" :page-size="page.size"
			 :total="page.total" layout="total, sizes, prev, pager, next, jumper">
			</el-pagination>
		</div>


	</div>
</template>

<script>
import apiList from '@/api/other'
import statisticsTime from './StatisticsTime'
	export default {
		components: {
			statisticsTime
		},
		data() {
			return {
				OrderBy:'',
				IsAsc:'',
				tooltip:{
					PV:'统计筛选时间内，页面被访问的次数，1人访问多次计多次',
					UV:'统计筛选时间内，页面被访问的人数，人数去重，1人访问多次计1人',
					ClickCount:'统计筛选时间内，页面被用户点击的次数，1人点击多次计多次',
					ClickMemberCount:'统计筛选时间内，在页面内产生点击行为的人数，1人点击多次计1人',
					ClickRate:'统计筛选时间内，页面点击人数/页面访客数',
					BounceRate:'统计筛选时间内，在页面没有发生点击行为的人数/页面访客数',
					StayTimeAvg:'统计筛选时间内，页面所有访客停留时长总和/页面访客数',
					ShareVisitCount:'统计筛选时间内，通过他人分享链接或分享图片访问该页面的次数，1人通过链接分享多次计多次。',
					ShareVisitMemberCount:'统计筛选时间内，通过他人分享链接或分享图片访问该页面的人数，1人访问多次计1人；',
					GuideSubmitOrderMoney:'统计筛选时间内，通过该页面直接引导成功下单的金额，不剔除已关闭或已退款订单，包含运费',
					GuideSubmitOrderMemberCount:'统计筛选时间内，通过该页面直接引导成功下单的人数，1人通过该页面引导创建多个订单，计1人，不剔除已关闭或已退款订单',
					GuideSubmitRate:'统计筛选时间内，该页面引导下单人数/页面访客数',
					GuidePayOrderMoney:'统计筛选时间内，通过该页面直接引导成功支付的金额，不剔除已关闭或已退款订单，包含运费',
					GuidePayOrderMemberCount:'统计筛选时间内，通过该页面直接引导成功支付的人数，1人通过该页面引导支付多个订单，计1人，不剔除已关闭或已退款订单',
					GuidePayRate:'统计筛选时间内，该页面引导支付人数/页面访客数',
					StartSubmitOrderMoney:'统计筛选时间内，访问商城的第1个页面是该页面，并且在商城成功下单的订单金额，不剔除已关闭或已退款订单，包含运费',
					StartSubmitOrderMemberCount:'统计筛选时间内，访问商城的第1个页面是该页面，并且在商城成功下单的人数，不剔除已关闭或已退款订单，包含运费;1个创建多个订单，计1人',
					StartSubmitRate:'统计筛选时间内，访问商城第1个页面是该页面的人数/访问下单人数"100%"',
					StartPayOrderMoney:'统计筛选时间内，访问商城的第1个页面是该页面，并且在商城成功支付的订单金额，不剔除已关闭或已退款订单，包含运费',
					StartPayOrderMemberCount:'统计筛选时间内，访问商城的第1个页面是该页面，并且在商城成功支付的人数，不剔除已关闭或已退款订单，包含运费;1个创建多个订单，计1人',
					StartPayRate:'统计筛选时间内，访问商城第1个页面是该页面的人数/访问支付人数*100%',
				},
				unShowType: [6,7, 8, 9, 10],
				keywords:'',
				Checks: [1, 3, 5],
				options: [{
						value: 1,
						label: '自然日'
					}, {
						value: 2,
						label: '自然周'
					}, {
						value: 3,
						label: '自然月'
					},
					{
						value: 4,
						label: '今日实时'
					}, {
						value: 5,
						label: '近7天'
					}, {
						value: 6,
						label: '近30天'
					}
				],
				tableData:[{
					type:'11111'
				}],
				page:{
				  size:20,
				  current:1,
				  total:0
				},
				groupDate:[],
				startDate:'',
				endDate:'',
				timeType:0,
				Referer:null,
				Refererlist:[
					{value:null,label:'全部'},
					{value:100008,label:'企业微信群'},
				]
			}
		},
		computed: {

		},
		created() {
		},
		methods: {
			chooseselect(e){
				// console.log(e)
				if(e==100008){
					this.Checks = this.Checks.filter(v=>{
						return v!=8&&v!=9
					})
				}
			},
			handleStructureCheck(){},
			handleSizeChange(val){
			  	this.page.size = val
				this.getData()
			},
			handleCurrentChange(val){
			  	this.page.current = val
				this.getData()
			},
			getRangeTime(startDate, endDate, timeType, startLineTime, endLineTime, groupDate) {
				this.groupDate = groupDate
				this.startDate = startDate
				this.endDate = endDate
				this.timeType = timeType
				// this.Checks = [1, 3, 5]
				this.getData()
			},
			
			handleSearch(){
				this.getData()
			},
			sortChange(column, prop, order) {
				if (column.order == "ascending") {
					// 正序
					this.IsAsc = true
					this.OrderBy = column.prop
				} else if(column.order == "descending") {
					this.IsAsc = false
					this.OrderBy = column.prop
				} else {
					this.IsAsc = ''
					this.OrderBy = ''
				}
				this.page.current = 1;
				this.getData()
			},
			async getData(){
				try {
					let data = {
						TimeType: this.timeType,
						Time: this.startDate,//当前日期
						KeyWord: this.keywords,
						Skip:this.page.size*(this.page.current-1),
						Take:this.page.size,
            OrderBy:this.OrderBy,
            IsAsc: this.IsAsc,
						Referer:this.Referer
					}

					if (this.timeType == 0) {
						data.StartTime = this.startDate
						data.EndTime = this.endDate
					}
					let result = await apiList.dataStatisPageAnalyze(data);
					this.tableData = result.Result.Results
					this.page.total=result.Result.Total

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				}
			}
		}
	}
</script>

<style lang="less" scoped>
.flex{
	display: flex;
}
.flex-center{
	align-items: center;
}
.flex-wrap{
	flex-wrap:wrap
}
	.main-container {
		background: #fff;
		padding: 15px;
		.icon {
			color: #999;
			margin-left: 5px;
			cursor: pointer;
		}
		.contents {
			background: #F5F5F5;
			padding: 15px;
			font-size: 14px;

			.con_items {
				width: 93%;

				.item_detail {
					width: 190px;
					margin-bottom: 10px;
				}
			}
		}

	}
</style>
